import * as React from "react";
import PropTypes from 'prop-types';
//MUI Imports
import {
    Avatar,
    Tooltip,
} from "@mui/material";
//Component Imports
import CustomIcon from './CustomIcon';
//Asset Imports

const useCondition = (name) => {
    switch (name) {
        case 'the_ancient_isles': return {
            title: 'Region: The Ancient Isles',
            icon: <CustomIcon name="ancient_isles" sx={{ p: 0.5 }} />,
        };
        case 'the_shores_of_plenty': return {
            title: 'Region: The Shores of Plenty',
            icon: <CustomIcon name="shores_of_plenty" sx={{ p: 0.5 }} />,
        };
        case 'the_wilds': return {
            title: 'Region: The Wilds',
            icon: <CustomIcon name="wilds" sx={{ p: 0.5 }} />,
        };
        case 'the_devils_roar': return {
            title: 'Region: The Devils Roar',
            icon: <CustomIcon name="devils_roar" sx={{ p: 0.5 }} />,
        };
        case 'shipwrecks': return {
            title: 'Shipwrecks',
            icon: <CustomIcon name="shipwreck" sx={{ p: 0.5 }} />,
        };
        case 'ponds': return {
            title: 'Ponds',
            icon: <CustomIcon name="pond" sx={{ p: 0.5 }} />,
        };
        case 'forts_or_skeleton_ships': return {
            title: 'Forts or Skeleton Ships',
            icon: <CustomIcon name="fort_skeleton_ship" sx={{ p: 0.5 }} />,
        };
        case 'storms': return {
            title: 'Storms',
            icon: <CustomIcon name="storm" sx={{ p: 0.5 }} />,
        };
        case 'night': return {
            title: 'Night',
            icon: <CustomIcon name="night" sx={{ p: 0.5 }} />,
        };
        case 'earthworm': return {
            title: 'Bait: Earthworm',
            icon: <CustomIcon name="worm" sx={{ p: 0.5 }} />,
        };
        case 'leech': return {
            title: 'Bait: Leach',
            icon: <CustomIcon name="leech" sx={{ p: 0.5 }} />,
        };
        case 'grub': return {
            title: 'Bait: Grub',
            icon: <CustomIcon name="grub" sx={{ p: 0.5 }} />,
        };
        default: throw new Error('No condition for: ' + name);
    }
};

/* Pass the name & sx props */
const ConditionIcon = ({ name }) => {
    const condition = useCondition(name);
    return (
        <Tooltip //Condition Icon
            title={condition.title}
        >
            <Avatar
                sx={{
                    width: 24,
                    height: 24,
                    ml: 0.5,
                    bgcolor: "blackTransparent.300",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "whiteTransparent.300",
                }}
            >
                {condition.icon}
            </Avatar>
        </Tooltip>
    );
};

/* Set the propTypes so we can catch bugs with typechecking */
ConditionIcon.propTypes = {
    name: PropTypes.string.isRequired,
}

export default ConditionIcon;