import * as React from "react";
//MUI Imports
import {
    Box,
    Button,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
//Component Imports
import CustomIcon from './CustomIcon';
//Asset Imports

export default function CatchCardChildMoreInfo(props) {
    var currencyIconColour = (props.child.currency !== 'gold' ? 'blue' : 'gold');
    var currencyIcon = < CustomIcon
        name={props.child.currency}
        sx={{
            color: currencyIconColour,
            width: "0.875rem",
            height: "0.875rem",
            ml: 0.25,
        }
        } />;
    //Columns
    var show_columns = [];
    for (var propertyName in props.child.regular_values) {
        if (props.child.regular_values[propertyName]) {
            show_columns.push({
                name: 'Regular',
                slug: 'regular_values'
            });
            break;
        }
    }
    for (propertyName in props.child.trophy_values) {
        if (props.child.trophy_values[propertyName]) {
            show_columns.push({
                name: 'Trophy',
                slug: 'trophy_values'
            });
            break;
        }
    }
    //Rows
    var show_rows = [];
    var types = [
        {
            name: 'Raw',
            slug: 'raw',
        },
        {
            name: 'Undercooked',
            slug: 'undercooked',
        },
        {
            name: 'Cooked',
            slug: 'cooked',
        },
        {
            name: 'Burnt',
            slug: 'burnt',
        },
    ];
    types.forEach((type) => {
        if (props.child.regular_values[type.slug] || props.child.trophy_values[type.slug]) {
            show_rows.push({
                name: type.name,
                slug: type.slug
            });
        }
    });

    //Currency Formatter
    function formatCurrency(number) {
        return parseInt(number).toLocaleString();
    }

    let tableHeaderCells = show_columns.map((column, i) => {
        return (<TableCell key={column.slug} align="right">{column.name}</TableCell>)
    });

    let tableRows = show_rows.map((row, i) => {
        return (
            <TableRow key={row.slug}>
                <TableCell>{row.name}</TableCell>
                {show_columns.map((column, i) => {
                    return (<TableCell key={column.slug} align="right">{formatCurrency(props.child[column.slug][row.slug])}{(props.child[column.slug][row.slug] ? currencyIcon : '')}</TableCell>)
                })}
            </TableRow>
        )
    });

    return (
        <Box //More Info
            sx={{
                p: 1,
            }}
        >
            <Divider sx={{ my: 1 }} />

            {(props.child.notes ? <Typography variant="body2" gutterBottom>Notes: {props.child.notes}</Typography> : '')}
            {(props.child.page_link_href ? <Button variant="contained" size="small" color="secondary" sx={{ mb: 2 }} target="_blank" href={props.child.page_link_href}>Fandom Wiki Link</Button> : '')}

            {
                (show_columns.length > 0
                    ?
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {tableHeaderCells}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    ''
                )
            }

        </Box>
    );
}
