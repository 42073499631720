import * as React from 'react';
//MUI Imports
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
//Component Imports
//Context Imports
//Asset Imports

export default function Settings(props) {

    //Get stored values
    let savedStartScreen = localStorage.getItem('Start Screen');
    let savedNotificationSound = localStorage.getItem('Notification Sound');
    let savedNotificationVibrate = localStorage.getItem('Notification Vibrate');
    let savedCookedNotificationBuffer = localStorage.getItem('Cooked Notification Buffer');
    let savedBurnedNotificationBuffer = localStorage.getItem('Burned Notification Buffer');
    let savedFireNotificationBuffer = localStorage.getItem('Fire Notification Buffer');
    let savedAutoRemoveStatus = localStorage.getItem('Auto Remove Status');
    let savedAutoRemovalDelay = localStorage.getItem('Auto Removal Delay');
    //Provide default values if empty    
    if (savedStartScreen === null) { savedStartScreen = 'catch'; localStorage.setItem('Start Screen', savedStartScreen); }
    if (savedNotificationSound === null) { savedNotificationSound = true; localStorage.setItem('Notification Sound', savedNotificationSound); }
    if (savedNotificationVibrate === null) { savedNotificationVibrate = true; localStorage.setItem('Notification Vibrate', savedNotificationVibrate); }
    if (savedCookedNotificationBuffer === null) { savedCookedNotificationBuffer = 3; localStorage.setItem('Cooked Notification Buffer', savedCookedNotificationBuffer); }
    if (savedBurnedNotificationBuffer === null) { savedBurnedNotificationBuffer = 10; localStorage.setItem('Burned Notification Buffer', savedBurnedNotificationBuffer); }
    if (savedFireNotificationBuffer === null) { savedFireNotificationBuffer = 15; localStorage.setItem('Fire Notification Buffer', savedFireNotificationBuffer); }
    if (savedAutoRemoveStatus === null) { savedAutoRemoveStatus = 'never'; localStorage.setItem('Auto Remove Status', savedAutoRemoveStatus); }
    if (savedAutoRemovalDelay === null) { savedAutoRemovalDelay = 10; localStorage.setItem('Auto Removal Delay', savedAutoRemovalDelay); }
    // Set and handle the settings
    const [startScreen, setStartScreen] = React.useState(savedStartScreen);
    const handleStartScreenChange = (event) => {
        setStartScreen(event.target.value);
        localStorage.setItem('Start Screen', event.target.value)
    }
    const [notificationSound, setNotificationSound] = React.useState((savedNotificationSound === 'true' ? true : false));
    const handleNotificationSoundChange = (event) => {
        setNotificationSound(event.target.checked);
        localStorage.setItem('Notification Sound', event.target.checked)
    }
    const [notificationVibrate, setNotificationVibrate] = React.useState((savedNotificationVibrate === 'true' ? true : false));
    const handleNotificationVibrateChange = (event) => {
        setNotificationVibrate(event.target.checked);
        localStorage.setItem('Notification Vibrate', event.target.checked)
    }
    const [cookedNotificationBuffer, setCookedNotificationBuffer] = React.useState(parseInt(savedCookedNotificationBuffer));
    const handleCookedNotificationBufferChange = (event) => {
        setCookedNotificationBuffer(event.target.value);
        localStorage.setItem('Cooked Notification Buffer', event.target.value)
    }
    const [burnedNotificationBuffer, setBurnedNotificationBuffer] = React.useState(parseInt(savedBurnedNotificationBuffer));
    const handleBurnedNotificationBufferChange = (event) => {
        setBurnedNotificationBuffer(event.target.value);
        localStorage.setItem('Burned Notification Buffer', event.target.value)
    }
    const [fireNotificationBuffer, setFireNotificationBuffer] = React.useState(parseInt(savedFireNotificationBuffer));
    const handleFireNotificationBufferChange = (event) => {
        setFireNotificationBuffer(event.target.value);
        localStorage.setItem('Fire Notification Buffer', event.target.value)
    }
    const [autoRemoveStatus, setAutoRemoveStatus] = React.useState(savedAutoRemoveStatus);
    const handleAutoRemoveStatusChange = (event) => {
        setAutoRemoveStatus(event.target.value);
        localStorage.setItem('Auto Remove Status', event.target.value)
    }
    const [autoRemovalDelay, setAutoRemovalDelay] = React.useState(parseInt(savedAutoRemovalDelay));
    const handleAutoRemovalDelayChange = (event) => {
        setAutoRemovalDelay(event.target.value);
        localStorage.setItem('Auto Removal Delay', event.target.value)
    }

    return (
        <Box
            component="div"
            className="content"
            sx={{
                ...{
                    p: 2,
                    maxWidth: 720,
                    mx: 'auto',
                },
                ...props.sx
            }}
        >

            <Typography variant="h2" component="h2" gutterBottom>
                Settings
            </Typography>

            <TextField
                label="Start Screen"
                margin="normal"
                select
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                value={startScreen}
                onChange={handleStartScreenChange}
            >
                <MenuItem value={'catch'}>Catch</MenuItem>
                <MenuItem value={'cook'}>Cook</MenuItem>
            </TextField>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={notificationSound} onClick={handleNotificationSoundChange} />} label="Play sounds" />
            </FormGroup>

            <FormGroup>
                <FormControlLabel control={<Checkbox checked={notificationVibrate} onClick={handleNotificationVibrateChange} />} label="Vibrate on notification (if this device supports this)" />
            </FormGroup>

            <TextField
                label="Cooked Notification Buffer"
                margin="normal"
                type="number"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">seconds </InputAdornment>,
                }}
                value={cookedNotificationBuffer}
                onChange={handleCookedNotificationBufferChange}
            />

            <TextField
                label="Burned Notification Buffer"
                margin="normal"
                type="number"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">seconds </InputAdornment>,
                }}
                value={burnedNotificationBuffer}
                onChange={handleBurnedNotificationBufferChange}
            />

            <TextField
                label="Fire Notification Buffer"
                margin="normal"
                type="number"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">seconds </InputAdornment>,
                }}
                value={fireNotificationBuffer}
                onChange={handleFireNotificationBufferChange}
            />

            <TextField
                label="Auto Remove Cooking Timer After"
                margin="normal"
                select
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                value={autoRemoveStatus}
                onChange={handleAutoRemoveStatusChange}
            >
                <MenuItem value={'cooked'}>Cooked</MenuItem>
                <MenuItem value={'burnt'}>Burned</MenuItem>
                <MenuItem value={'fire'}>Fire</MenuItem>
                <MenuItem value={'never'}>Never</MenuItem>
            </TextField>

            <TextField
                label="Auto Removal Delay"
                margin="normal"
                type="number"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">seconds </InputAdornment>,
                }}
                value={autoRemovalDelay}
                onChange={handleAutoRemovalDelayChange}
            />

        </Box>
    );
}
