import * as React from 'react';
//MUI Imports
import {
    Box,
    Typography,
} from "@mui/material";
//Component Imports
//Context Imports
//Asset Imports

export default function Disclaimer(props) {

    return (
        <Box
            component="div"
            className="content"
            sx={{
                ...{
                    p: 2,
                    maxWidth: 720,
                    mx: 'auto',
                },
                ...props.sx
            }}
        >

            <Typography variant="h2" component="h2" gutterBottom>
                Disclaimer
            </Typography>

            <Typography variant="body1" gutterBottom>
                Microsoft, Rare, the Rare logo, Sea of Thieves are trademarks of the Microsoft group of companies. All rights are reserved worldwide. This app has no official link with Rare or Sea of Thieves. All artwork, screenshots, characters or other recognizable features of the intellectual property relating to these trademarks are likewise the intellectual property of Rare.
            </Typography>

        </Box>
    );
}
