import * as React from "react";
//MUI Imports
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { useDispatch } from 'react-redux';
//Data Imports
import cookingTypes from "../data/cooking-types.json";
//Context Imports
import { changePage } from '../slice/activePage'
import { addCookingItem } from '../slice/cookingItems'
//Component Imports
import useLongPress from '../functions/useLongPress';
import CustomIcon from './CustomIcon';
import ConditionIcon from './ConditionIcon';
import CatchCardChildMoreInfo from "./CatchCardChildMoreInfo";
//Asset Imports

export default function CatchCardChild(props) {

    const dispatch = useDispatch()

    //Handle caught counter
    const savedCaughtCount = parseInt(localStorage.getItem(props.child.name + ' Caught Count'));
    const [caughtCount, setCaughtCount] = React.useState((savedCaughtCount > 0 ? savedCaughtCount : 0));
    const handleCaughtCountIncrement = () => {
        let newCaughtCount = caughtCount + 1;
        setCaughtCount(newCaughtCount);
        localStorage.setItem(props.child.name + ' Caught Count', newCaughtCount)
    };
    const handleCaughtCountDecrement = () => {
        let newCaughtCount;
        if (caughtCount > 0) {
            newCaughtCount = caughtCount - 1;
        } else {
            newCaughtCount = 0;
        }
        setCaughtCount(newCaughtCount);
        localStorage.setItem(props.child.name + ' Caught Count', newCaughtCount)
    };
    const catchButtonLongPress = useLongPress(() => {
        handleCaughtCountDecrement();
    }, 500);

    //Setup image and handle image dialog
    const childImg = require('../assets/images/' + props.child.image).default;
    const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
    const handleClickImageDialogOpen = () => {
        setImageDialogOpen(true);
    };
    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
    };

    //Handle cook options dialog
    const regularCookingType = cookingTypes[0];
    const trophyCookingType = cookingTypes[1];
    const [cookDialogOpen, setCookDialogOpen] = React.useState(false);
    const handleClickCookDialogOpen = () => {
        setCookDialogOpen(true);
    };
    const handleCookDialogClose = () => {
        setCookDialogOpen(false);
    };
    const [cookDialogSize, setCookDialogSize] = React.useState('Regular Fish');
    const handleCookDialogSizeChange = (event) => {
        setCookDialogSize(event.target.value);
    };
    const handleCookDialogSubmit = () => {
        let selectedCookingType = (cookDialogSize === 'Trophy Fish' ? trophyCookingType : regularCookingType);
        dispatch(addCookingItem({ cookingType: selectedCookingType, cookingSubtype: props.child }));
        setCookDialogOpen(false);
        dispatch(changePage('cook'));
    };

    //Handle more Info Display
    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const handleClickMoreInfo = () => {
        setMoreInfoOpen(!moreInfoOpen);
    };

    //Get all currency values
    var all_values = [];
    for (var propertyName in props.child.regular_values) {
        if (props.child.regular_values[propertyName]) {
            all_values.push(parseInt(props.child.regular_values[propertyName]));
        }
    }
    for (propertyName in props.child.trophy_values) {
        if (props.child.trophy_values[propertyName]) {
            all_values.push(parseInt(props.child.trophy_values[propertyName]));
        }
    }
    //Find min and max values
    var minValue = Math.min.apply(Math, all_values);
    var maxValue = Math.max.apply(Math, all_values);
    //Create range string
    var displayValueRange = '';
    if (minValue && minValue !== Infinity) {
        displayValueRange += minValue.toLocaleString();
    }
    if (minValue && minValue !== Infinity && maxValue && maxValue !== Infinity && (minValue !== maxValue)) {
        displayValueRange += '~';
    }
    if (maxValue && maxValue !== -Infinity && (minValue !== maxValue)) {
        displayValueRange += maxValue.toLocaleString();
    }

    let conditions = Object.keys(props.child.conditions).map(function (key, index) {
        if (props.child.conditions[key] === 'TRUE') {
            return <ConditionIcon key={key} name={key} />;
        } else {
            return false;
        }
    });

    return (
        <Box // Family Child
            sx={{
                mx: 0.5,
                mb: 1,
                borderRadius: "3px",
                backgroundColor: "whiteTransparent.400",
            }}
        >

            <Box //Main Content
                sx={{
                    display: "flex",
                }}
            >
                <Box //Image Container
                    sx={{
                        flex: 1,
                        pr: 0.5,
                    }}
                >
                    <Box //Image
                        sx={{
                            m: 0.5,
                            backgroundColor: "#007f6654",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            aspectRatio: "1 / 1",
                            borderRadius: "3px",
                            backgroundImage: `url(${childImg})`,
                        }}
                        onClick={handleClickImageDialogOpen}
                    ></Box>
                    <Dialog
                        open={imageDialogOpen}
                        onClose={handleImageDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <img
                                src={childImg}
                                alt={props.child.name}
                                style={{ maxWidth: "100%" }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={handleImageDialogClose}
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>

                <Box //Details
                    sx={{
                        flex: 1,
                        p: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography //Title
                        variant="h3"
                        className="name"
                        sx={{
                            mb: 0.5,
                            fontSize: "0.9rem",
                            lineHeight: "1em",
                            fontWeight: "bold",
                        }}
                    >
                        {props.child.name}
                    </Typography>

                    <Box //Caught
                    >
                        <Typography>Caught: {caughtCount}</Typography>
                    </Box>
                </Box>

                <Box //Info
                    sx={{
                        flex: 1,
                        p: 0.5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box //Conditions
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {conditions}
                        {(props.bait ? <ConditionIcon name={props.bait} /> : '')}
                    </Box>

                    <Box //Value
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Typography sx={{ fontSize: '0.975rem' }}>
                            {displayValueRange}
                            {(
                                props.child.currency === "gold"
                                    ?
                                    <CustomIcon
                                        name="gold"
                                        sx={{
                                            color: "gold",
                                            width: "0.875rem",
                                            height: "0.875rem",
                                            ml: 0.25,
                                        }}
                                    />
                                    :
                                    ''
                            )}
                            {(
                                props.child.currency === "doubloon"
                                    ?
                                    <CustomIcon
                                        name="doubloon"
                                        sx={{
                                            color: "blue",
                                            width: "0.875rem",
                                            height: "0.875rem",
                                            ml: 0.25,
                                        }}
                                    />
                                    :
                                    ''
                            )}
                        </Typography>
                    </Box>

                    <Box //Interactions
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton //More Info
                            onClick={handleClickMoreInfo}
                            sx={{
                                height: "30px",
                                width: "30px",
                                ml: 1,
                                boxShadow: 3,
                                bgcolor: "blackTransparent.300",
                                color: "white",
                                "&:hover, &.Mui-focusVisible": {
                                    bgcolor: "blackTransparent.300",
                                },
                            }}
                        >
                            <Typography>i</Typography>
                        </IconButton>
                        <IconButton // Catch
                            sx={{
                                height: "30px",
                                width: "30px",
                                ml: 1,
                                boxShadow: 3,
                                bgcolor: "secondary.main",
                                color: "white",
                                "&:hover, &.Mui-focusVisible": {
                                    bgcolor: "secondary.main",
                                },
                            }}
                            {...catchButtonLongPress}
                            onClick={() => { handleCaughtCountIncrement(); }}
                        >
                            <CustomIcon name="hook" sx={{ p: 0.5 }} />
                        </IconButton>
                        {(props.child.cookable ?
                            <IconButton //Cook
                                sx={{
                                    height: "30px",
                                    width: "30px",
                                    ml: 1,
                                    boxShadow: 3,
                                    bgcolor: "secondary.main",
                                    color: "white",
                                    "&:hover, &.Mui-focusVisible": {
                                        bgcolor: "secondary.main",
                                    },
                                }}
                                onClick={handleClickCookDialogOpen}
                            >
                                <CustomIcon name="pan" sx={{ p: 0.5 }} />
                            </IconButton>
                            :
                            ''
                        )}
                        <Dialog
                            open={cookDialogOpen}
                            onClose={handleCookDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle sx={{ fontSize: "1.25rem" }}>
                                Choose a Size
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    What size is your Ruby Splashtail?
                                </DialogContentText>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Size
                                    </InputLabel>
                                    <Select
                                        label="Size"
                                        value={cookDialogSize}
                                        onChange={handleCookDialogSizeChange}
                                    >
                                        <MenuItem value="Regular Fish">Regular</MenuItem>
                                        <MenuItem value="Trophy Fish">Trophy</MenuItem>
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={handleCookDialogClose}
                                    autoFocus
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCookDialogSubmit}
                                >
                                    Cook
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>

                </Box>
            </Box>

            <Collapse in={moreInfoOpen}>
                {(moreInfoOpen ? <CatchCardChildMoreInfo child={props.child} /> : '')}
            </Collapse>

        </Box>
    );
}
