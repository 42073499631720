import * as React from "react";
//MUI Imports
import {
    Box,
    Paper,
} from "@mui/material";
//Component Imports
import CatchCardChild from "./CatchCardChild";
import CatchCardHeader from "./CatchCardHeader";
//Asset Imports

export default function CatchCard(props) {

    let childCards = props.family.children.map(child => (
        <CatchCardChild key={child.name} child={child} bait={props.family.required_bait} />
    ));

    return (
        <Paper //Family
            variant="outlined"
            sx={{
                overflow: "hidden",
                mb: 2,
                backgroundColor: "primary.main",
            }}
        >
            <CatchCardHeader family={props.family} />

            <Box //Family Children
            >
                {childCards}
            </Box>
        </Paper>
    );
}
