import * as React from 'react';
//MUI Imports
import {
    AppBar,
    Box,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from "@mui/icons-material/Share";
import { useDispatch } from 'react-redux';
//Component Imports
import CustomIcon from './CustomIcon';
//Context Imports
import { changePage } from '../slice/activePage'
//Asset Imports
import { ReactComponent as LogoSVG } from "../logo.svg";
import textStrings from "../data/text-strings.json";


function pluckRandomString(string) {
    let randomIndex = Math.floor((Math.random() * textStrings[string].length));
    return textStrings[string][randomIndex];
}

export default function AppBarMenu() {
    const dispatch = useDispatch()

    const [state, setState] = React.useState({
        mainMenuDrawer: false,
    });

    const toggleDrawer = (drawerID, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [drawerID]: open });
    };

    const menuItemClick = (menuItem) => (event) => {
        switch (menuItem) {
            case 'catch':
                setState({ ...state, 'mainMenuDrawer': false });
                dispatch(changePage('catch'));
                break;
            case 'cook':
                setState({ ...state, 'mainMenuDrawer': false });
                dispatch(changePage('cook'));
                break;
            case 'settings':
                setState({ ...state, 'mainMenuDrawer': false });
                dispatch(changePage('settings'));
                break;
            case 'disclaimer':
                setState({ ...state, 'mainMenuDrawer': false });
                dispatch(changePage('disclaimer'));
                break;
            case 'share':
                if (navigator.share) {
                    navigator.share({
                        title: 'SoT Tackle Box & Cooking Timer',
                        text: 'Check out SoT Tackle Box & Cooking Timer app',
                        url: 'https://play.google.com/store/apps/details?id=com.nathaneyre.sottb.twa',
                    })
                        .then(() => console.log('Successful share'))
                        .catch((error) => console.log('Error sharing', error));
                }
                break;
            default:
                console.log('No Action Found');
        }
    };

    const mainMenu = () => (
        <Box
            sx={{ minWidth: 250 }}
            role="presentation"
        >
            <List>
                <ListItemButton onClick={menuItemClick('catch')}>
                    <ListItemIcon>
                        <CustomIcon name="hook" />
                    </ListItemIcon>
                    <ListItemText primary='Catch' />
                </ListItemButton>
                <ListItemButton onClick={menuItemClick('cook')}>
                    <ListItemIcon>
                        <CustomIcon name="pan" />
                    </ListItemIcon>
                    <ListItemText primary='Cook' />
                </ListItemButton>
            </List>
            <Divider />

            <List>
                <ListItemButton component="a" target="_blank" href="https://seaofthieves.fandom.com/wiki/Ponds#List_of_Pond_Locations">
                    <ListItemIcon>
                        <CustomIcon name="pond" />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>Pond List <OpenInNewIcon fontSize="inherit" color="action" /></React.Fragment>} />
                </ListItemButton>
                <ListItemButton component="a" target="_blank" href="./RegionMap.svg">
                    <ListItemIcon>
                        <CustomIcon name="region" />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>Region Map <OpenInNewIcon fontSize="inherit" color="action" /></React.Fragment>} />
                </ListItemButton>
                <ListItemButton component="a" target="_blank" href="https://www.merfolkslullaby.com/map?tab=weather&utm_source=seaofnews&utm_medium=referral&latitude=18.61980670844565&longitude=-64.41434121033315&zoom=12.816946256817841&realm=sea-of-thieves">
                    <ListItemIcon>
                        <CustomIcon name="storm" />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>Storm Tracker <OpenInNewIcon fontSize="inherit" color="action" /></React.Fragment>} />
                </ListItemButton>
            </List>
            <Divider />

            <List>
                <ListItemButton onClick={menuItemClick('settings')}>
                    <ListItemIcon>
                        <CustomIcon name="settings" />
                    </ListItemIcon>
                    <ListItemText primary='Settings' />
                </ListItemButton>
                <ListItemButton onClick={menuItemClick('disclaimer')}>
                    <ListItemIcon>
                        <CustomIcon name="disclaimer" />
                    </ListItemIcon>
                    <ListItemText primary='Disclaimer' />
                </ListItemButton>
                {(navigator.share ?
                    <ListItemButton onClick={menuItemClick('share')}>
                        <ListItemIcon>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText primary='Share App' />
                    </ListItemButton>
                    :
                    ''
                )}
                <ListItemButton component="a" target="_blank" href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=KYNUBMTM6VKSN">
                    <ListItemIcon>
                        <CustomIcon name="dev" />
                    </ListItemIcon>
                    <ListItemText primary={<React.Fragment>Support the Developer <OpenInNewIcon fontSize="inherit" color="action" /></React.Fragment>} />
                </ListItemButton>
            </List>

        </Box>
    );

    return (
        <React.Fragment>

            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer('mainMenuDrawer', true)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h1" sx={{ display: 'none' }}>
                        Sea of Thieves Tackle Box and Cooking Timer
                    </Typography>
                    <SvgIcon sx={{ width: 185, height: 24 }} viewBox="0 0 185 24">
                        <LogoSVG />
                    </SvgIcon>
                </Toolbar>
            </AppBar>
            <Toolbar />

            <SwipeableDrawer
                open={state['mainMenuDrawer']}
                onClose={toggleDrawer('mainMenuDrawer', false)}
                onOpen={toggleDrawer('mainMenuDrawer', true)}
                sx={{
                    '& .MuiDrawer-paper': {
                        justifyContent: 'space-between'
                    }
                }}
            >
                {mainMenu()}
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        textAlign: 'center',
                        fontSize: '0.75em',
                        fontStyle: 'italic',
                        color: 'grey.400',
                    }}
                >
                    {pluckRandomString('dedication_affirmation')}
                </Box>
            </SwipeableDrawer>

        </React.Fragment>
    );
}
