import * as React from "react";
//MUI Imports
import {
    Box,
} from "@mui/material";
import { useSelector } from 'react-redux';
//Component Imports
import CookCard from "../components/CookCard";
import CookMenu from "../components/CookMenu";
//Context Imports
//Asset Imports

export default function Cook(props) {

    const cookingItems = useSelector((state) => state.cookingItems.value);

    // The wake lock sentinel.
    // Function that attempts to request a screen wake lock.
    const requestWakeLock = async () => {
        try {
            await navigator.wakeLock.request();
        } catch (err) {
            console.error(`${err.name}, ${err.message}`);
        }
    };
    if (cookingItems.length >= 1) {
        requestWakeLock();
    }

    let cookCards = cookingItems.map(cookingItem => (
        < CookCard key={cookingItem.id} id={cookingItem.id} cookType={cookingItem.cookingType} item={cookingItem.item} />
    ));

    return (
        <Box //Container
            sx={{
                ...{
                    p: 2,
                },
                ...props.sx
            }}
        >
            <Box //Card Container
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem 1rem",
                }}
            >
                {cookCards}
            </Box>

            <CookMenu />

        </Box>
    );
}
