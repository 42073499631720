import * as React from "react";
import PropTypes from 'prop-types';
//MUI Imports
import { SvgIcon } from "@mui/material";
//Component Imports
//Asset Imports
import { ReactComponent as AncientIslesSVG } from "../assets/icons/ancient_isles.svg";
import { ReactComponent as BaitSVG } from "../assets/icons/bait.svg";
import { ReactComponent as BeastMeatSVG } from "../assets/icons/beast_meat.svg";
import { ReactComponent as CaughtSVG } from "../assets/icons/caught.svg";
import { ReactComponent as ConditionsSVG } from "../assets/icons/conditions.svg";
import { ReactComponent as DevilsRoarSVG } from "../assets/icons/devils_roar.svg";
import { ReactComponent as DevSVG } from "../assets/icons/dev.svg";
import { ReactComponent as DisclaimerSVG } from "../assets/icons/disclaimer.svg";
import { ReactComponent as DoubloonSVG } from "../assets/icons/doubloon.svg";
import { ReactComponent as FilterSVG } from "../assets/icons/filter.svg";
import { ReactComponent as FishRegularSVG } from "../assets/icons/fish_regular.svg";
import { ReactComponent as FishTrophySVG } from "../assets/icons/fish_trophy.svg";
import { ReactComponent as FortSkeletonShipSVG } from "../assets/icons/fort_skeleton_ship.svg";
import { ReactComponent as FruitSVG } from "../assets/icons/fruit.svg";
import { ReactComponent as GoldSVG } from "../assets/icons/gold.svg";
import { ReactComponent as GrubSVG } from "../assets/icons/grub.svg";
import { ReactComponent as HookSVG } from "../assets/icons/hook.svg";
import { ReactComponent as LeechSVG } from "../assets/icons/leech.svg";
import { ReactComponent as LoaderSVG } from "../assets/icons/loader.svg";
import { ReactComponent as MeatSVG } from "../assets/icons/meat.svg";
import { ReactComponent as NightSVG } from "../assets/icons/night.svg";
import { ReactComponent as NoBaitSVG } from "../assets/icons/no_bait.svg";
import { ReactComponent as PanSVG } from "../assets/icons/pan.svg";
import { ReactComponent as PondSVG } from "../assets/icons/pond.svg";
import { ReactComponent as RegionSVG } from "../assets/icons/region.svg";
import { ReactComponent as SelectSVG } from "../assets/icons/select.svg";
import { ReactComponent as SettingsSVG } from "../assets/icons/settings.svg";
import { ReactComponent as ShipwreckSVG } from "../assets/icons/shipwreck.svg";
import { ReactComponent as ShoresOfPlentySVG } from "../assets/icons/shores_of_plenty.svg";
import { ReactComponent as StormSVG } from "../assets/icons/storm.svg";
import { ReactComponent as UncaughtSVG } from "../assets/icons/uncaught.svg";
import { ReactComponent as WildsSVG } from "../assets/icons/wilds.svg";
import { ReactComponent as WormSVG } from "../assets/icons/worm.svg";

/* create a function that will pick which icon to use */
const useIcon = (name) => {
    switch (name) {
        case 'ancient_isles': return AncientIslesSVG;
        case 'bait': return BaitSVG;
        case 'beast_meat': return BeastMeatSVG;
        case 'caught': return CaughtSVG;
        case 'conditions': return ConditionsSVG;
        case 'devils_roar': return DevilsRoarSVG;
        case 'dev': return DevSVG;
        case 'disclaimer': return DisclaimerSVG;
        case 'doubloon': return DoubloonSVG;
        case 'filter': return FilterSVG;
        case 'fish_regular': return FishRegularSVG;
        case 'fish_trophy': return FishTrophySVG;
        case 'fort_skeleton_ship': return FortSkeletonShipSVG;
        case 'fruit': return FruitSVG;
        case 'gold': return GoldSVG;
        case 'grub': return GrubSVG;
        case 'hook': return HookSVG;
        case 'leech': return LeechSVG;
        case 'loader': return LoaderSVG;
        case 'meat': return MeatSVG;
        case 'night': return NightSVG;
        case 'no_bait': return NoBaitSVG;
        case 'pan': return PanSVG;
        case 'pond': return PondSVG;
        case 'region': return RegionSVG;
        case 'select': return SelectSVG;
        case 'settings': return SettingsSVG;
        case 'shipwreck': return ShipwreckSVG;
        case 'shores_of_plenty': return ShoresOfPlentySVG;
        case 'storm': return StormSVG;
        case 'uncaught': return UncaughtSVG;
        case 'wilds': return WildsSVG;
        case 'worm': return WormSVG;
        default: throw new Error('No SVG for: ' + name);
    }
};

/* Pass the name & sx props */
const Icon = ({ name, sx = {} }) => {
    const SVG = useIcon(name);
    return (
        <SvgIcon sx={sx}>
            <SVG />
        </SvgIcon>
    );
};

/* Set the propTypes so we can catch bugs with typechecking */
Icon.propTypes = {
    name: PropTypes.string.isRequired,
    sx: PropTypes.object,
}

export default Icon;
