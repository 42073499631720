import * as React from 'react';
//MUI Imports
import { CssBaseline, ThemeProvider } from '@mui/material';
import '@fontsource/roboto';
import { useSelector } from 'react-redux';
//CSS Import
import theme from './theme';
//Component Imports
//Context Import
//Asset Imports
import AppBarMenu from './components/AppBarMenu';
import Settings from './pages/Settings';
import Disclaimer from './pages/Disclaimer';
import Catch from './pages/Catch';
import Cook from './pages/Cook';
import BottomNavigationMenu from './components/BottomNavigationMenu';

function App() {

  const activePage = useSelector((state) => state.activePage.value);

  window.onbeforeunload = function () { return "Are you sure you want to leave the app?"; };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBarMenu />
      {(activePage === 'settings' ?
        <Settings />
        :
        ''
      )}
      {(activePage === 'disclaimer' ?
        <Disclaimer />
        :
        ''
      )}
      {(activePage === 'catch' ?
        <Catch />
        :
        ''
      )}
      <Cook sx={{ display: (activePage === 'cook' ? 'block' : 'none') }} />

      <BottomNavigationMenu />
    </ThemeProvider>
  );
}

export default App;
