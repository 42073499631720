import * as React from "react";
//MUI Imports
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
//Component Imports
import ConditionIcon from "./ConditionIcon";
//Asset Imports

export default function CatchCardHeader(props) {
    const familyImg = require('../assets/images/' + props.family.image).default;

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let conditionIcons = Object.keys(props.family.conditions).map(function (key, index) {
        if (props.family.conditions[key] === 'TRUE') {
            return <ConditionIcon key={key} name={key} />;
        } else {
            return false;
        }
    });

    return (
        <Box //Header
            sx={{
                p: 1,
                aspectRatio: "2.39 / 1",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${familyImg})`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Box //Title Bar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography //Title
                    variant="h2"
                    component="h2"
                    gutterBottom
                    sx={{
                        textShadow: "0 0 20px black",
                        color: "white",
                    }}
                >
                    {props.family.name}
                </Typography>

                <Box //Conditions
                    className="conditions"
                    sx={{
                        display: "flex",
                    }}
                >
                    {conditionIcons}
                    {(props.family.required_bait ? <ConditionIcon name={props.family.required_bait} /> : '')}
                </Box>
            </Box>

            <Box //Interactive
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                {(props.family.action_link !== '' ?
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{
                            ml: 0.5,
                        }}
                        component="a"
                        target="_blank"
                        href={props.family.action_link}
                    >
                        {props.family.action_text}
                    </Button>
                    :
                    ''
                )}
                {(props.family.description !== '' ?
                    <React.Fragment>
                        <IconButton
                            size="small"
                            sx={{
                                ml: 0.5,
                            }}
                            onClick={handleClickOpen}
                        >
                            <InfoIcon />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1.25rem" }}>{props.family.name}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">{props.family.description}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} autoFocus>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                    :
                    ''
                )}
            </Box>
        </Box>
    );
}
