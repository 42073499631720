import { createSlice } from '@reduxjs/toolkit'


//import addedSound from "../assets/sounds/added.mp3";
//import removedSound from "../assets/sounds/removed.mp3";

export const cookingItemsSlice = createSlice({
  name: 'cookingItems',
  initialState: {
    value: [],
  },
  reducers: {
    addCookingItem: (state, action) => {
      let cardId = Math.round(((Math.random() * 9999) + 1));
      let newCookingItem = {
        id: cardId,
        cookingType: action.payload.cookingType,
        item: action.payload.cookingSubtype
      };
      let newCookingItems = [...state.value];
      newCookingItems.unshift(newCookingItem);
      state.value = newCookingItems;

      //Play Sound
      //if (localStorage.getItem('Notification Sound') === 'true') {
      //  let audio = new Audio(addedSound);
      //  audio.play();
      //}

    },
    removeCookingItem: (state, action) => {
      let newCookingItems = [...state.value];
      let removeIndex = newCookingItems.findIndex(item => item.id === action.payload);
      newCookingItems.splice(removeIndex, 1);
      state.value = newCookingItems;

      //Play Sound
      //if (localStorage.getItem('Notification Sound') === 'true') {
      //  let audio = new Audio(removedSound);
      //  audio.play();
      //}

    },
  },
})

// Action creators are generated for each case reducer function
export const { addCookingItem, removeCookingItem } = cookingItemsSlice.actions

export default cookingItemsSlice.reducer