import * as React from 'react';
//MUI Imports
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
} from "@mui/material";
//Component Imports
import CustomIcon from './CustomIcon';
//Asset Imports

export default function CatchFilters(props) {

    function handleCatchFilterChipsClick(filter_key) {
        let localActiveFilters = [];
        props.activeFilters.map((activeFilter) => {
            localActiveFilters.push(activeFilter);
            return false;
        });
        if (localActiveFilters.includes(filter_key)) {
            let filterIndex = localActiveFilters.indexOf(filter_key);
            localActiveFilters.splice(filterIndex, 1);
        } else {
            localActiveFilters.push(filter_key);
        }
        return localActiveFilters;
    };

    const [openFiltersDialog, setOpenFiltersDialog] = React.useState(false);
    const handleClickFiltersDialogOpen = () => {
        setOpenFiltersDialog(true);
    };
    const handleFiltersDialogClose = () => {
        setOpenFiltersDialog(false);
    };
    const handleFiltersDialogClear = () => {
        props.changeActiveFilters([]);
        setOpenFiltersDialog(false);
    };

    var catchFilterDialogChips = Object.keys(props.catchFilters).map(function (key) {
        return <Box sx={{ m: 0.25 }} key={key}>
            <Chip
                icon={<CustomIcon name={props.catchFilters[key].icon} sx={{ ml: 1 }} />}
                label={props.catchFilters[key].title}
                //onClick={() => { handleCatchFilterChipsClick(key) }}
                onClick={() => props.changeActiveFilters(handleCatchFilterChipsClick(key))}
                color={(props.activeFilters.includes(key) ? 'primary' : 'default')}
            />
        </Box>
    });

    var catchFilterBarChips = Object.keys(props.catchFilters).map(function (key) {
        if (props.activeFilters.includes(key)) {
            return <Box
                key={key}
                sx={{ m: 0.25 }}
            >
                <Chip
                    size="small"
                    icon={<CustomIcon name={props.catchFilters[key].icon} sx={{ ml: 1 }} />}
                    label={props.catchFilters[key].title}
                    sx={{ background: 'blackTransparent.300' }}
                    color="primary"
                    onClick={() => props.changeActiveFilters(handleCatchFilterChipsClick(key))}
                />
            </Box>
        }
        return false;
    });

    return (
        <React.Fragment>

            <Dialog open={openFiltersDialog} onClose={handleFiltersDialogClose}>
                <DialogTitle sx={{ fontSize: '1.25rem' }}>Filters</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        Filter the fish using the toggles below:
                    </DialogContentText>


                    <Divider
                        variant="middle"
                        flexItem
                        sx={{
                            my: '0.5em',
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {catchFilterDialogChips}
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleFiltersDialogClear}>Clear</Button>
                    <Button variant="contained" onClick={handleFiltersDialogClose}>Done</Button>
                </DialogActions>
            </Dialog>

            <Box
                component="div"
                sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'primary.main',
                }}
            >

                <IconButton
                    size="small"
                    aria-label="filter"
                    edge="start"
                    onClick={handleClickFiltersDialogOpen}
                >
                    <CustomIcon name="filter" sx={{ color: 'white' }} />
                    <Box
                        sx={{
                            pl: 1,
                            color: 'white'
                        }}
                        component="span"
                    >
                        Filter
                    </Box>
                </IconButton>



                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                        marginLeft: '0.5em',
                        marginRight: '0.5em',
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0,
                        m: 0,
                    }}
                    component="ul"
                >

                    {catchFilterBarChips}

                </Box>

            </Box>

        </React.Fragment >
    );
}
