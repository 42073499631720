
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


const theme = createTheme({
    palette: {
        primary: {
            main: '#007f66',
        },
        secondary: {
            main: '#4d05a6',
        },
        black: '#000000',
        blackTransparent: {
            50: '#0000000d',
            100: '#0000001a',
            200: '#00000033',
            300: '#0000004d',
            400: '#00000066',
            500: '#00000080',
            600: '#00000099',
            700: '#000000b3',
            800: '#000000cc',
            900: '#000000e6',
        },
        white: '#FFFFFF',
        whiteTransparent: {
            50: '#FFFFFF0d',
            100: '#FFFFFF1a',
            200: '#FFFFFF33',
            300: '#FFFFFF4d',
            400: '#FFFFFF66',
            500: '#FFFFFF80',
            600: '#FFFFFF99',
            700: '#FFFFFFb3',
            800: '#FFFFFFcc',
            900: '#FFFFFFe6',
        },
        grey: grey,
        gold: '#EDD84C',
        blue: '#5E81A2',
        transparent: '#00000000',
    },
    typography: {
        h1: {
            fontSize: "2rem",
        },
        h2: {
            fontSize: "1.5rem",
        },
        h3: {
            fontSize: "1.17rem",
        },
        h4: {
            fontSize: "1rem",
        },
        h5: {
            fontSize: "0.83rem",
        },
        h6: {
            fontSize: "0.67rem",
        },
    },
});

export default theme;