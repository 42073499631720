import * as React from "react";
//MUI Imports
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { connect } from 'react-redux';
//Component Imports
import CustomIcon from './CustomIcon';
//Context Imports
import { removeCookingItem } from '../slice/cookingItems'
//Asset Imports
import textStrings from "../data/text-strings.json";
import cookedSound from "../assets/sounds/cooked.mp3";
import burntSound from "../assets/sounds/burnt.mp3";
import fireSound from "../assets/sounds/fire.mp3";

const DoubloonIcon = (
    <CustomIcon
        name="doubloon"
        sx={{
            color: "blue",
            width: "0.875rem",
            height: "0.875rem",
            ml: 0.25,
        }}
    />
);
const GoldIcon = (
    <CustomIcon
        name="gold"
        sx={{
            color: "gold",
            width: "0.875rem",
            height: "0.875rem",
            ml: 0.25,
        }}
    />
);

class CookCard extends React.Component {


    //Called right before rendering the element(s) in the DOM
    constructor(props) {
        super(props);

        this.styles = {};

        this.tickTime = 42;

        this.id = ((Math.random() * 9999) + 1);

        this.milSecUntilUndercooked = this.props.cookType.sec_until_undercooked * 1000;
        this.milSecUntilCooked = this.props.cookType.sec_until_cooked * 1000;
        this.milSecUntilBurnt = this.props.cookType.sec_until_burnt * 1000;
        this.milSecUntilFire = (this.props.cookType.sec_until_burnt * 1000) + 300000;

        this.notificationPlayed = false;

        //Set image
        this.image = require('../assets/images/' + this.props.cookType.image).default;
        if (this.props.item.image) {
            this.image = require('../assets/images/' + this.props.item.image).default;
        }
        //Set name
        this.name = this.props.cookType.name + ' ';
        if (this.props.item.name) {
            this.name = this.props.item.name + ' ';
        }
        if (this.props.cookType.name === 'Trophy Fish') {
            this.name = 'Trophy ' + this.name;
        }

        //Set currency
        this.currency = '';
        if (this.props.item.currency) {
            this.currency = this.props.item.currency;
        }
        //Set values
        this.values = {};
        if (this.props.cookType.name === 'Trophy Fish' && this.props.item.trophy_values) {
            this.values = this.props.item.trophy_values;
        } else if (this.props.item.regular_values) {
            this.values = this.props.item.regular_values;
        }

        //Set default widget state
        this.state = {
            milSecCooking: 0,
            progressValue: 0,
            progressColor: 'success',
            cookedState: 'raw',
            cookedStateValue: '',
            cookedToState: 'cooked',
            cookedToStateValue: '',
            cookedToMilSecUntil: this.milSecUntilCooked,
            phrase: this.getPhrase('raw')
        }

    }

    //Add an event listener specificity for this widget 
    componentDidMount() {
        this.timerTick = setInterval(
            () => this.tick(),
            this.tickTime
        );
    }

    //Remove the event listener for this widget when unmounting
    componentWillUnmount() {
        clearInterval(this.timerTick);
    }

    tick() {
        //Determine cooking state
        let milSecCooking = this.state.milSecCooking + this.tickTime;
        //Calculate Progress
        let undercookedProgress = milSecCooking / this.milSecUntilUndercooked * 100;
        let cookedProgress = milSecCooking / this.milSecUntilCooked * 100;
        let burntProgress = milSecCooking / this.milSecUntilBurnt * 100;
        let fireProgress = milSecCooking / this.milSecUntilFire * 100;
        //Set variables
        let progressValue = this.state.progressValue;
        let progressColor = this.state.progressColor;
        let cookedState = this.state.cookedState;
        let cookedToState = this.state.cookedToState;
        let cookedToMilSecUntil = this.state.cookedToMilSecUntil;
        let phrase = this.state.phrase;
        //Determine cooking state
        if (undercookedProgress <= 100) {
            progressValue = (milSecCooking) / (this.milSecUntilCooked) * 100;
            if (cookedState !== 'raw') {
                this.scrollToCard();
                progressColor = 'success';
                cookedState = 'raw';
                cookedToState = 'cooked';
                cookedToMilSecUntil = this.milSecUntilCooked;
                phrase = this.getPhrase(cookedState);
                this.notificationPlayed = false;
            }
        } else if (cookedProgress <= 100) {
            progressValue = (milSecCooking) / (this.milSecUntilCooked) * 100;
            if (cookedState !== 'undercooked') {
                progressColor = 'success';
                cookedState = 'undercooked';
                cookedToState = 'cooked';
                cookedToMilSecUntil = this.milSecUntilCooked;
                phrase = this.getPhrase(cookedState);
                this.notificationPlayed = false;
            }
        } else if (burntProgress <= 100) {
            progressValue = (milSecCooking - this.milSecUntilCooked) / (this.milSecUntilBurnt - this.milSecUntilCooked) * 100;
            if (cookedState !== 'cooked') {
                this.scrollToCard();
                progressColor = 'warning';
                cookedState = 'cooked';
                cookedToState = 'burnt';
                cookedToMilSecUntil = this.milSecUntilBurnt;
                phrase = this.getPhrase(cookedState);
                this.notificationPlayed = false;
            }
        } else if (fireProgress <= 100) {
            progressValue = (milSecCooking - this.milSecUntilBurnt) / (this.milSecUntilFire - this.milSecUntilBurnt) * 100;
            if (cookedState !== 'burnt') {
                this.scrollToCard();
                progressColor = 'error';
                cookedState = 'burnt';
                cookedToState = 'fire';
                cookedToMilSecUntil = this.milSecUntilFire;
                phrase = this.getPhrase(cookedState);
                this.notificationPlayed = false;
            }
        } else {
            progressValue = 100;
            if (cookedState !== 'fire') {
                this.scrollToCard();
                progressColor = 'error';
                cookedState = 'fire';
                cookedToState = 'fire';
                cookedToMilSecUntil = this.milSecUntilFire;
                phrase = this.getPhrase(cookedState);
                this.notificationPlayed = false;
            }
        }
        this.setState({
            milSecCooking: milSecCooking,
            progressValue: progressValue,
            progressColor: progressColor,
            cookedState: cookedState,
            cookedToState: cookedToState,
            cookedToMilSecUntil: cookedToMilSecUntil,
            phrase: phrase,
        });

        //Check if Cooked Notification should play
        if (
            cookedState === 'undercooked'
            && milSecCooking >= (this.milSecUntilCooked - (localStorage.getItem('Cooked Notification Buffer') * 1000))
            && !this.notificationPlayed
        ) {
            this.notification('cooked');
        } else if (
            cookedState === 'cooked'
            && milSecCooking >= (this.milSecUntilBurnt - (localStorage.getItem('Burned Notification Buffer') * 1000))
            && !this.notificationPlayed
        ) {
            this.notification('burnt');
        } else if (
            cookedState === 'burnt'
            && milSecCooking >= (this.milSecUntilFire - (localStorage.getItem('Fire Notification Buffer') * 1000))
            && !this.notificationPlayed
        ) {
            this.notification('fire');
        }

        //Check if this should be removed
        if (
            cookedState === localStorage.getItem('Auto Remove Status')
        ) {
            setTimeout(() => {
                this.props.removeCookingItem(this.props.id);
            }, (localStorage.getItem('Auto Removal Delay') * 1000));
        }

    }

    getPhrase = (cookedState) => {
        let phrase = pluckRandomString('attention');
        phrase += this.name;
        if (['raw', 'undercooked'].includes(cookedState)) {
            phrase += pluckRandomString('cooking') + ' ';
            phrase += pluckRandomString('wait');
        } else if (['cooked'].includes(cookedState)) {
            phrase += pluckRandomString('cooked') + ' ';
            phrase += pluckRandomString('collect');
        } else if (['burnt'].includes(cookedState)) {
            phrase += pluckRandomString('burned') + ' ';
            phrase += pluckRandomString('trash');
        } else if (['fire'].includes(cookedState)) {
            phrase += pluckRandomString('blazed') + ' ';
            phrase += pluckRandomString('douse');
        }
        return phrase;
    };

    displayCookedState = (cookedState) => {
        let stateName = 'Raw';
        let icon = GoldIcon;
        let value = '';

        switch (cookedState) {
            case 'undercooked':
                stateName = 'Undercooked';
                break;
            case 'cooked':
                stateName = 'Cooked';
                break;
            case 'burnt':
                stateName = 'Burnt';
                break;
            case 'fire':
                stateName = 'On Fire!';
                break;
            default:
                stateName = 'Raw';
        }
        icon = (this.currency === 'doubloon' ? DoubloonIcon : GoldIcon);
        if (this.values[cookedState]) {
            value = this.values[cookedState];
        } else if (cookedState === 'fire') {
            value = this.values['burnt'];
        }

        return (
            <React.Fragment>
                <Typography
                    variant="h3"
                    component="div"
                    color="white"
                    sx={{
                        textAlign: "center",
                    }}
                >
                    {stateName}
                </Typography>
                <Typography
                    variant="h4"
                    component="div"
                    color="white"
                    sx={{ textAlign: "center" }}
                >
                    {(value ? value : '')} {(value ? icon : '')}
                </Typography>
            </React.Fragment>
        )
    }

    scrollToCard() {
        if (this.props.activePage === 'cook') {
            let id = 'cook-card-' + this.id;
            let yOffset = -64;
            let element = document.getElementById(id);
            let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    notification(alertType) {

        //Play Sound
        if (localStorage.getItem('Notification Sound') === 'true') {
            this.notificationPlayed = true;
            let audioSound;
            switch (alertType) {
                case 'cooked':
                    audioSound = cookedSound;
                    break;
                case 'burnt':
                    audioSound = burntSound;
                    break;
                case 'fire':
                    audioSound = fireSound;
                    break;
                default:
                    audioSound = cookedSound;
                    break;
            }
            let audio = new Audio(audioSound);
            audio.play();
        }

        //Vibrate Device
        if (localStorage.getItem('Notification Vibrate') === 'true') {
            try {
                navigator.vibrate(500); // vibrate for 200ms
            } catch (err) {
                console.error(`${err.name}, ${err.message}`);
            }
        }

    }

    //Render
    render() {

        return (
            <Card
                id={'cook-card-' + this.id}
                raised={true}
                sx={{
                    position: "relative",
                    flex: "0 1 auto",
                    boxSizing: "border-box",
                    minWidth: {
                        xs: "100%",
                        sm: "50%",
                        md: "33%",
                        lg: "25%",
                        xl: "20%",
                    },
                    backgroundColor: "primary.main",
                    color: "white",
                }}
            >
                <IconButton
                    size="large"
                    sx={{
                        position: "absolute",
                        right: 0,
                        ml: 0.5,
                    }}
                    onClick={() => { this.props.removeCookingItem(this.props.id) }}
                >
                    <DeleteForeverIcon
                        sx={{ color: "whiteTransparent.300" }}
                        fontSize="large"
                    />
                </IconButton>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {this.props.id}
                    <Box
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "inline-block",
                                width: "70%",
                                height: "70%",
                                position: "relative",
                            }}
                        >
                            <CircularProgress
                                key={this.state.cookedState}
                                size={"100%"}
                                thickness={3.6}
                                variant="determinate"
                                value={this.state.progressValue}
                                color={this.state.progressColor}
                                sx={{
                                    transform: "rotate(0) !important",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundImage: `url(${this.image})`,
                                    borderRadius: "999em",
                                    boxShadow: '0px 0px 6px 6px rgba(255,255,255,0.66);',
                                    "& svg": {
                                        transform: "rotate(-90deg)",
                                        margin: "-0.5px",
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        color="grey.200"
                        sx={{
                            textAlign: "center",
                            fontSize: "1rem",
                            fontStyle: "italic",
                            my: 1,
                        }}
                    >
                        {this.state.phrase}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                px: 2,
                            }}
                        >
                            {this.displayCookedState(this.state.cookedState)}
                        </Box>
                        <Box
                            sx={{
                                px: 2,
                            }}
                        >
                            <DoubleArrowIcon sx={{ verticalAlign: "text-top" }} />{" "}
                        </Box>
                        <Box
                            sx={{
                                px: 2,
                            }}
                        >
                            {this.displayCookedState(this.state.cookedToState)}
                        </Box>
                    </Box>

                    <Typography
                        variant="subtitle1"
                        component="div"
                        color="white"
                        sx={{ textAlign: "center", fontSize: "1.75rem" }}
                    >
                        {formatTime(((this.state.cookedToMilSecUntil - this.state.milSecCooking) > 0 ? this.state.cookedToMilSecUntil - this.state.milSecCooking : 0))}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

}


function pluckRandomString(string) {
    let randomIndex = Math.floor((Math.random() * textStrings[string].length));
    return textStrings[string][randomIndex];
}

function formatTime(ms) {
    if (!ms) return '';

    //Populate value diff
    let days = Math.floor(ms / 86400000); // days
    let hrs = Math.floor((ms % 86400000) / 3600000); // hours
    let mins = Math.floor(((ms % 86400000) % 3600000) / 60000); // minutes
    let secs = Math.floor((((ms % 86400000) % 3600000) % 60000) / 1000); // seconds

    //create string
    let str = '';
    if (days) str += ` ${days}d`;
    if (hrs) str += ` ${hrs}h`;
    if (mins) str += ` ${mins}m`;
    if (secs) str += ` ${secs}s`;

    return str;
}

const mapStateToProps = (state) => {
    return { activePage: state.activePage.value };
};
const mapDispatchToProps = (dispatch) => {
    return {
        removeCookingItem: (id) => dispatch(removeCookingItem(id))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CookCard)