import * as React from "react";
//MUI Imports
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from "@mui/material";
import { useDispatch } from 'react-redux';
//Data Imports
import cookingTypes from "../data/cooking-types.json";
import fishingFamilies from "../data/fishing-families.json";
//Context Imports
import { addCookingItem } from '../slice/cookingItems'
//Asset Imports
import CustomIcon from './CustomIcon';

let regularFishIndex = cookingTypes.findIndex((cookingType) => { return cookingType.name === "Regular Fish" });
let trophyFishIndex = cookingTypes.findIndex((cookingType) => { return cookingType.name === "Trophy Fish" });
fishingFamilies.forEach((fishingFamily) => {
    if (fishingFamily.name !== 'Treacherous Plunder') {
        fishingFamily.children.forEach((fishingFamilyChild) => {
            cookingTypes[regularFishIndex].children.push(fishingFamilyChild);
            cookingTypes[trophyFishIndex].children.push(fishingFamilyChild);
        });
    }
});

//Note: Pass the Cooking Type and Subtype to start timer



export default function CookMenu(props) {

    const dispatch = useDispatch()

    //Cooking Choose Dialog
    const [openCookingChooseDialog, setOpenCookingChooseDialog] = React.useState(false);
    const handleClickOpenCookingChooseDialog = () => {
        setOpenCookingChooseDialog(true);
    };
    const handleCloseCookingChooseDialog = () => {
        setOpenCookingChooseDialog(false);
    };
    //Primary Type Select
    const [cookingChooseType, setCookingChooseType] = React.useState("");
    const [cookingChooseShowSubtype, setCookingChooseShowSubtype] = React.useState(false);
    const [cookingChooseSubtype, setCookingChooseSubtype] = React.useState("");
    const handleChooseTypeValueChange = (event) => {
        if (event.target.value === "") {
            setCookingChooseType("");
            setCookingChooseShowSubtype(false);
        } else {
            setCookingChooseSubtype("");
            let cookingChooseTypeSelected = cookingTypes.filter((cookingType) => {
                return event.target.value === cookingType.name;
            });
            setCookingChooseType(cookingChooseTypeSelected[0]);
            if (cookingChooseTypeSelected[0].children.length > 1) {
                setCookingChooseShowSubtype(true);
            } else {
                setCookingChooseType(cookingChooseTypeSelected[0].children[0]);
                setCookingChooseShowSubtype(false);
            }
        }
    };
    const handleChooseSubtypeValueChange = (event) => {
        if (event.target.value === "") {
            setCookingChooseSubtype("");
        } else {
            let cookingChooseSubtypeSelected = cookingChooseType.children.filter((cookingSubtype) => {
                return event.target.value === cookingSubtype.name;
            });
            setCookingChooseSubtype(cookingChooseSubtypeSelected[0]);
        }
    };
    const handleCookingChooseDialogCook = () => {
        dispatch(addCookingItem({ cookingType: cookingChooseType, cookingSubtype: cookingChooseSubtype }));
        handleCloseCookingChooseDialog();
    };


    //Speed Dial
    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
    const handleOpenSpeedDial = () => setOpenSpeedDial(true);
    const handleCloseSpeedDial = () => setOpenSpeedDial(false);
    const handleSpeedDialCookAction = (cookingType) => {
        dispatch(addCookingItem({ cookingType: cookingType, cookingSubtype: {} }));
        handleCloseSpeedDial();
    };
    const speedDialActions = [];
    speedDialActions.push({
        name: 'Choose...',
        icon: <CustomIcon name='select' />,
        handle: handleClickOpenCookingChooseDialog
    });
    cookingTypes.forEach((cookingType) => {
        speedDialActions.push({
            name: cookingType.name.replace(/ /g, '\xa0'),
            icon: <CustomIcon name={cookingType.icon} />,
            handle: () => { handleSpeedDialCookAction(cookingType) }
        });
    });

    let speedDialActionsElm = speedDialActions.map((speedDialAction) => (
        <SpeedDialAction
            key={speedDialAction.name}
            icon={speedDialAction.icon}
            tooltipTitle={speedDialAction.name}
            tooltipOpen
            onClick={speedDialAction.handle}
        />
    ));

    let cookingTypeMenuItems = cookingTypes.map(
        cookingType => (
            <MenuItem key={cookingType.name} value={cookingType.name}>{cookingType.name}</MenuItem>
        )
    );

    let cookingSubtypeMenuItems = (cookingChooseShowSubtype ?
        cookingChooseType.children.map(
            cookingSubtype => (
                <MenuItem key={cookingSubtype.name} value={cookingSubtype.name}>{cookingSubtype.name}</MenuItem>
            )
        )
        :
        ''
    );

    return (
        <React.Fragment>

            <Backdrop open={openSpeedDial} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: "fixed", bottom: 64, right: 8 }}
                icon={<SpeedDialIcon />}
                onClose={handleCloseSpeedDial}
                onOpen={handleOpenSpeedDial}
                open={openSpeedDial}
                FabProps={{
                    color: 'secondary',
                }}
            >
                {speedDialActionsElm}
            </SpeedDial>
            <Dialog
                open={openCookingChooseDialog}
                onClose={handleCloseCookingChooseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ fontSize: "1.25rem" }}>
                    Choose an Item
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What are we cooking?
                    </DialogContentText>

                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="demo-simple-select-label">
                            Type
                        </InputLabel>
                        <Select label="Type" value={(cookingChooseType ? cookingChooseType.name : "")} onChange={handleChooseTypeValueChange} >
                            <MenuItem value=''>Select...</MenuItem>
                            {cookingTypeMenuItems}
                        </Select>
                    </FormControl>

                    {(
                        cookingChooseShowSubtype ?
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel id="demo-simple-select-label">
                                    Subtype
                                </InputLabel>
                                <Select label="Type" value={(cookingChooseSubtype ? cookingChooseSubtype.name : "")} onChange={handleChooseSubtypeValueChange} >
                                    <MenuItem value=''>Select...</MenuItem>
                                    {cookingSubtypeMenuItems}
                                </Select>
                            </FormControl>
                            :
                            ''
                    )}

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleCloseCookingChooseDialog}
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={handleCookingChooseDialogCook}
                    >
                        Cook
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}
