import * as React from "react";
//MUI Imports
import {
    Box,
    Grid,
} from "@mui/material";
//Component Imports
import CatchCard from "../components/CatchCard";
import CatchFilters from "../components/CatchFilters";
//Context Imports
//Data Imports
import fishingFamilies from "../data/fishing-families.json";
//Asset Imports

let catchFilters = {
    the_ancient_isles: {
        title: 'Ancient Isles',
        icon: "ancient_isles",
    },
    the_shores_of_plenty: {
        title: 'Shores of Plenty',
        icon: "shores_of_plenty",
    },
    the_wilds: {
        title: 'Wilds',
        icon: "wilds",
    },
    the_devils_roar: {
        title: 'Devils Roar',
        icon: "devils_roar",
    },
    shipwrecks: {
        title: 'Shipwrecks',
        icon: "shipwreck",
    },
    ponds: {
        title: 'Ponds',
        icon: "pond",
    },
    forts_or_skeleton_ships: {
        title: 'Forts or Skeleton Ships',
        icon: "fort_skeleton_ship",
    },
    storms: {
        title: 'Storms',
        icon: "storm",
    },
    night: {
        title: 'Night',
        icon: "night",
    },
    earthworm: {
        title: 'Earthworm',
        icon: "worm",
    },
    leech: {
        title: 'Leech',
        icon: "leech",
    },
    grub: {
        title: 'Grub',
        icon: "grub",
    },
    caught: {
        title: 'Caught',
        icon: "caught",
    },
    uncaught: {
        title: 'Uncaught',
        icon: "uncaught",
    }
}

export default function Catch(props) {

    //Get stored values
    let savedActiveFilters = localStorage.getItem('Active Filters');
    if (savedActiveFilters) savedActiveFilters = JSON.parse(savedActiveFilters);
    //Provide default values if empty
    if (savedActiveFilters === null) { savedActiveFilters = []; localStorage.setItem('Active Filters', JSON.stringify(savedActiveFilters)); }    // Set and handle the active filters

    const [activeFilters, setActiveFilters] = React.useState(savedActiveFilters);
    const handleActiveFiltersChange = (newActiveFilters) => {
        setActiveFilters(newActiveFilters);
        localStorage.setItem('Active Filters', JSON.stringify(newActiveFilters))
    }

    //Filter each family to see if it has valid children
    let filteredFishingFamilies = JSON.parse(JSON.stringify(fishingFamilies));
    filteredFishingFamilies = filteredFishingFamilies.filter(function (fishingFamily) {
        //Check the families children to see if they are valid
        let filteredFishingFamilyChildren = fishingFamily.children.filter(function (fishingFamilyChild) {
            let hasValidConditions = true;
            activeFilters.forEach(filter => {
                if (["earthworm", "leech", "grub"].includes(filter)) {
                    if (fishingFamily.required_bait !== filter) {
                        hasValidConditions = false;
                    }
                } else if (['caught', 'uncaught'].includes(filter)) {
                    let savedCaughtCount = parseInt(localStorage.getItem(fishingFamilyChild.name + ' Caught Count'));
                    if (filter === 'caught' && !savedCaughtCount) {
                        hasValidConditions = false;
                    } else if (filter === 'uncaught' && savedCaughtCount > 0) {
                        hasValidConditions = false;
                    }
                } else if (fishingFamilyChild.conditions[filter] !== 'TRUE') {
                    hasValidConditions = false;
                };
            });
            return hasValidConditions;
        });
        fishingFamily.children = filteredFishingFamilyChildren;
        return fishingFamily.children.length > 0;
    });

    let filteredFishingFamiliesCards = filteredFishingFamilies.map(
        family => (
            <Grid key={family.name} item xs={12} sm={6} md={6} lg={4} xl={3}>
                <CatchCard family={family} />
            </Grid>
        )
    )

    return (
        <Box //Container
            sx={{
                ...{
                },
                ...props.sx
            }}
        >
            <CatchFilters catchFilters={catchFilters} activeFilters={activeFilters} changeActiveFilters={(newActiveFilters) => handleActiveFiltersChange(newActiveFilters)} />

            <Box //Container
                component="div"
                sx={{
                    p: 2,
                }}
            >
                <Grid container spacing={2}>
                    {filteredFishingFamiliesCards}
                </Grid>
            </Box>
        </Box>
    );
}
