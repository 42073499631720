import * as React from 'react';
//MUI Imports
import {
    BottomNavigation,
    BottomNavigationAction,
    Toolbar,
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
//Component Imports
import CustomIcon from './CustomIcon';
//Context Imports
import { changePage } from '../slice/activePage'
//Asset Imports

export default function BottomNavigationMenu() {

    const activePage = useSelector((state) => state.activePage.value);
    const dispatch = useDispatch()

    // Use "activePage" global state
    const handleChange = (event, newValue) => {
        dispatch(changePage(newValue));
    };

    return (
        <React.Fragment>

            <Toolbar />
            <BottomNavigation
                value={activePage}
                onChange={handleChange}
                color="primary"
                sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: '0',
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: 'blackTransparent.100',
                }}
            >
                <BottomNavigationAction
                    label="Catch"
                    value="catch"
                    icon={<CustomIcon name="hook" />}
                />
                <BottomNavigationAction
                    label="Cook"
                    value="cook"
                    icon={<CustomIcon name="pan" />}
                />
            </BottomNavigation>

        </React.Fragment>
    );
}
