import { createSlice } from '@reduxjs/toolkit'

let savedStartScreen = localStorage.getItem('Start Screen');
if (savedStartScreen === null) { savedStartScreen = 'catch'; }

export const activePageSlice = createSlice({
  name: 'activePage',
  initialState: {
    value: savedStartScreen,
  },
  reducers: {
    changePage: (state, action) => {
      state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { changePage } = activePageSlice.actions

export default activePageSlice.reducer